import Birria from "../images/Birria.jpg";
import Pollo from "../images/Pollo.jpg";
import Lomo from "../images/Lomo.jpg";
import BoxLounch from "../images/BoxLounch.jpg";
import Taquiza from "../images/Taquiza.jpg";
import Cafe from "../images/Cafe.jpg";
import { LocalAtmOutlined, LocalMoviesRounded } from "@mui/icons-material";

export const MenuList = [
  {
    name: "Birria de Res",
    description:
      "Nuestra Birria es hecha con productos de calidad, y es hecha al en pozo con leña, lo cual le da un sabor especial",
    image: Birria,
    price: 95,
  },
  {
    name: "Pollo",
    description:
      "Pollo Relleno o Pollo Cordon Bleu, un platillo que le da un toque especial a tus mesas ",
    image: Pollo,
    price: 100,
  },
  {
    name: "Lomo Mechado",
    description:
      "Un clasico para su evento, El Lomo Mechado, un platillo que deleita a los invitados",
    image: Lomo,
    price: 100,
  },
  {
    name: "Box Lounch",
    description:
      "Desayuno o Comida Express, un kit para eventos rapidos y o masivos",
    image: BoxLounch,
    price: 100,
  },
  {
    name: "Taquiza",
    description:
      "Los mejores guizados para tus invitados",
    image: Taquiza,
    price: 100,
  },
  {
    name: "Barra de Cafe",
    description:
      "Barra de cafe, con desayunos ligeros para tus reuniones",
    image: Cafe,
    price: 100,
  },
];
