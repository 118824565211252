import React from "react";
import Layout from "../components/Layout/Layout";
import { Box, Typography } from "@mui/material";

const Acerca = () => {
  return (
    <Layout>
      <Box
        sx={{
          my: 15,
          textAlign: "center",
          p: 2,
          "& h4": {
            fontWeight: "bold",
            my: 2,
            fontSize: "2rem",
          },
          "& p": {
            textAlign: "justify",
          },
          "@media (max-width:600px)": {
            mt: 0,
            "& h4 ": {
              fontSize: "1.5rem",
            },
          },
        }}
      >
        <Typography variant="h4">Nuestra Historia</Typography>
        <p>
          Monteón Catering inicia en los 90s, es una empresa familiar, con la mision de dar la mejor calidad de comida para sus eventos.
        </p>
        <br />
        <p>
        Estamos contigo en en tus eventos mas importantes, con un servicio personalizado y detallista, para que  tener un evento unico!
        </p>

        
      </Box>

      

    </Layout>
  );
  
};

export default Acerca;
