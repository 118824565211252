import React from "react";
import Layout from "./../components/Layout/Layout";
//import { Link } from "react-router-dom";
import Banner from "../images/banner.jpg";
import "../styles/HomeStyles.css";

const Home = () => {
  return (
    <Layout>
      <div className="home" style={{ backgroundImage: `url(${Banner})` }}>
        <div className="headerContainer">
          <h1>Monteón</h1>
          <p>Comida para Eventos</p>
          <a href="https://wa.me/5213323846243">  <button>Cotizacion</button> </a>
                  
          </div>
      </div>
    </Layout>
  );
};

export default Home;
